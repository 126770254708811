<style lang="scss">
.transcript {
  white-space: pre-wrap;
  background-color: transparent;
  font-size: 100%
}
</style>
<!--eslint-disable-->
<template>
  <div class="network-page">
    <section v-if="!loading">
      <div v-if="location" class="row">
        <div class="col-12">
          <b-card class="" title="Location Details">
            This page contains details on your location.
          </b-card>
        </div>
        <div class="col">
          <b-card class="">
            <section>
              <h4 class="mb-1">Details</h4>

              <div class="row mt-2">
                <div class="col-md-12">
                  <table class="table table-striped">
                    <tbody>
                    <tr>
                      <td class="pr-1">Name</td>
                      <td>{{ location.name }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">Max Items</td>
                      <td>
                        <span v-if="location.max_items > -1">{{location.max_items}}</span>
                        <span v-else>No Limit</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">System</td>
                      <td v-if="location.system">Yes</td>
                      <td v-else>No</td>
                    </tr>
                    <tr>
                      <td class="pr-1">Volumetric Controls</td>
                      <td v-if="location.apply_volumetric_controls">Enabled</td>
                      <td v-else>Disabled</td>
                    </tr>
                    <tr>
                      <td class="pr-1">Allow Possession Requests To</td>
                      <td v-if="location.enable_possession_requests_to">Enabled</td>
                      <td v-else>Disabled</td>
                    </tr>
                    <tr>
                      <td class="pr-1">Allow Possession Requests From</td>
                      <td v-if="location.enable_possession_requests_from">Enabled</td>
                      <td v-else>Disabled</td>
                    </tr>

                    </tbody>
                  </table>
                </div>
                <div class="col-md-4"></div>
              </div>
            </section>
          </b-card>

          <b-card v-if="this.location" class="mt-2">
            <section>
              <h4 class="mb-1">Possessions</h4>

              <PossessionsTable
                :default-filters="{location_id: this.location.id}"
                :show-location-filter="false"
                :show-create-button="false"
              />

            </section>
          </b-card>

        </div>

        <div class="col-3" v-if="!location.system">
          <b-card>
            <p class="font-weight-bolder">Actions</p>
            <b-btn :disabled="location.system" class="full-width mb-1" variant="primary"
                   @click="showModal('modal-update')">
              Update Location
            </b-btn>
            <b-btn v-if="$can('Delete', 'Locations')"
                   :disabled="location.system" class="full-width mb-1" variant="danger"
                   @click="showDeleteModal">
              Delete Location
            </b-btn>
          </b-card>
        </div>
      </div>
      <div v-else>Location not found</div>


      <b-modal id="modal-update" hide-footer title="Update Location">
        <update-location :location="this.location" @close="closeModals"></update-location>
      </b-modal>

    </section>

    <!-- Loading -->
    <section v-else>
      <b-spinner label="Loading Location" variant="primary"></b-spinner>
    </section>
  </div>
</template>
<!--eslint-enable-->

<script>
import LocationsService from "@/services/LocationsService";
import UpdateLocation from "@/views/locations/modals/UpdateLocation.vue";
import PossessionsTable from "@/views/possessions/sections/PossessionTable.vue";

export default {
  components: {PossessionsTable, UpdateLocation},
  data() {
    return {
      loading: true,
      location: null,
    }
  },
  mounted() {
    this.getLocation()
  },
  methods: {
    async getLocation() {
      this.loading = true
      try {
        const res = await LocationsService.get(this.$route.params.id)
        this.location = res.data
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get location, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
      this.loading = false;
    },
    async deleteLocation() {
      try {
        await LocationsService.delete(this.$route.params.id)
        await this.$router.push({name: 'locations'})
        this.$toast.success('Locations was successfully deleted.', {
          toastClassName: ['toast-std', 'success-toast'],
        })
      } catch (err) {
        const res = err.response
        let errorText = 'Could not delete location, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    showDeleteModal() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this location? This action cannot be undone.', {
        title: 'Delete Location',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value) {
          this.deleteLocation()
        }
      })
    },
    showModal(modalName) {
      this.$bvModal.show(modalName);
    },
    closeModals() {
      this.$bvModal.hide('modal-update');
      this.getLocation();
    },
  },
}
</script>
